// Override default variables before the import
// $body-bg: #000;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import '~react-notifications/lib/notifications.css';

// default font family to all elements
* {
  font-family: 'Montserrat', sans-serif;
}

.main-color {
  color: #666;
}
